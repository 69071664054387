import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import Cookies from 'js-cookie';

const Header = () => {
  const hasToken = !!Cookies.get('token');

  const handleSignOut = () => {
    Cookies.remove('token', { domain: '.auroraclient.lol' });
    window.location.reload(); // Force a reload to update the header after sign out
  };

  return (
    <header className="flex items-center justify-between p-4">
      <Link to="/" className="flex items-center">
        <img src={logo} alt="Aurora Client Logo" className="w-12" />
      </Link>

    </header>
  );
};

export default Header;
