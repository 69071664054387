import React, { useEffect, useState } from 'react';
import axios from 'axios';
import getFeatures from '../api/getFeatures';

const Homepage = () => {
  const [features, setFeatures] = useState([]);
  const [prices, setPrices] = useState([]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const fetchFeatures = async () => {
      const data = await getFeatures();
      setFeatures(data);
    };

    const fetchPrices = async () => {
      try {
        const response = await axios.get('https://api.auroraclient.lol/prices');
        setPrices(response.data.prices);
      } catch (error) {
        console.error('Error fetching prices:', error);
        setPrices([]);
      }
    };

    fetchFeatures();
    fetchPrices();
  }, []);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const visibleFeatures = expanded ? features : features.slice(0, 6);

  const getPrice = (category) => {
    const price = prices.find((item) => item.category === category);
    return price ? price.value : 0;
  };

  return (
    <div className="container mx-auto px-4">
      <section id="hero" className="py-12">
        <div className="text-center">
          <h1 className="text-6xl font-bold mb-4 ">Aurora Client</h1>
          <h1 className="text-2xl font-bold mb-4">Enhance your gameplay like never before...</h1>
          <p className="text-lg mb-6">
            Get Aurora - an open-source and free Minecraft Utility Mod that contains never-seen-before features for free!
          </p>
          <div className="flex justify-center">
            <a
              href="https://github.com/AuroraQoL/AuroraClient"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md focus:outline-none"
            >
              Github Repository
            </a>
            <a
              href="https://api.auroraclient.lol/invite"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md focus:outline-none ml-4"
            >
              Join our Discord
            </a>
          </div>
        </div>
      </section>

      <section id="features" className="py-6">
        <div className="text-center">
          <h2 className="text-4xl font-bold mb-8">Features</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 ">
            {visibleFeatures.map((feature) => (
              <div
                key={feature.name}
                className="bg-primary hover:bg-accent text-white py-2 px-4 rounded-md focus:outline-none"
              >
                <h3 className="text-xl font-semibold mb-2 text-white">{feature.name}</h3>
                <p className="mb-4 text-white">{feature.description}</p>
              </div>
            ))}
          </div>
          {features.length > 6 && (
            <button
              className="text-accent hover:text-accent focus:outline-none mt-4"
              onClick={toggleExpansion}
            >
              {expanded ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>
      </section>

      <section id="pricing" className="py-12">
        <div className="text-center">
          <h2 className="text-4xl font-bold mb-8">Pricing Table</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-primary hover:bg-accent py-2 px-4 rounded-md focus:outline-none p-6 shadow">
              <h3 className="text-2xl font-semibold mb-4">Aurora</h3>
              <p className="text-xl font-semibold mb-4">Free</p>
              <ul className="mb-6">
                <li>Lifetime access</li>
                <li>Krypton Script Demo</li>
                <li>Full CH Scanner</li>
                <li>Automatic Updates</li>
                <li>Support on discord</li>
                <li>QoL</li>
              </ul>
              <a
                href="https://github.com/AuroraQoL/AuroraClient"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-secondary text-white py-2 px-4 rounded-md block w-full focus:outline-none pricing-button"
              >
                Download from GitHub
              </a>
            </div>
            <div className="bg-primary hover:bg-accent py-2 px-4 rounded-md focus:outline-none p-6 shadow">
              <h3 className="text-2xl font-semibold mb-4">Aurora Supporter</h3>
              <p className="text-xl font-semibold mb-4">
                <span className="text-gray-600 line-through">$10</span> ${getPrice('supporter')}
              </p>
              <ul className="mb-6">
                <li>Lifetime Access</li>
                <li>Krypton Scripting Engine</li>
                <li>Full CH Scanner</li>
                <li>Automatic Updates</li>
                <li>Faster Support</li>
                <li>1 PC per license</li>
              </ul>
              <a
                href="https://api.auroraclient.lol/invite"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-secondary text-white py-2 px-4 rounded-md block w-full focus:outline-none pricing-button"
              >
                Purchase on Discord
              </a>
            </div>
            {prices.length > 0 && (
              <div className="bg-primary hover:bg-accent py-2 px-4 rounded-md focus:outline-none p-6 shadow">
                <h3 className="text-2xl font-semibold mb-4">Custom options</h3>
                <p className="text-xl font-semibold mb-4">Customize your name and cape!</p>
                <ul className="mb-6">
                  <li>Custom Cape: ${getPrice('cape')}</li>
                  <li>Custom Name: ${getPrice('customname')}</li>
                  <li>Custom Cape and Custom Name: ${getPrice('cape customname')}</li>
                  <li>Supporter and Custom Cape: ${getPrice('supporter cape')}</li>
                  <li>Supporter and Custom Name: ${getPrice('supporter customname')}</li>
                  <li>Supporter, Custom Cape, and Custom Name: ${getPrice('supporter cape customname')}</li>
                </ul>
                <a
                  href="https://api.auroraclient.lol/invite"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-secondary text-white py-2 px-4 rounded-md block w-full focus:outline-none pricing-button"
                >
                  Purchase Extras
                </a>
              </div>
            )}
          </div>
        </div>
      </section>

      <section id="faq" className="py-12">
        <div className="text-center">
          <h2 className="text-4xl font-bold mb-8">FAQ</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-primary hover:bg-accent text-white py-2 px-4 rounded-md focus:outline-none">
              <h3 className="text-xl font-semibold mb-2">Is Aurora Free?</h3>
              <p className="mb-4">Yes, Aurora offers a free version with lifetime access.</p>
            </div>
            <div className="bg-primary hover:bg-accent text-white py-2 px-4 rounded-md focus:outline-none">
              <h3 className="text-xl font-semibold mb-2">Is Aurora a RAT?</h3>
              <p className="mb-4">No, Aurora is not a remote access trojan (RAT). It is a Minecraft Utility Mod.</p>
            </div>
            <div className="bg-primary hover:bg-accent text-white py-2 px-4 rounded-md focus:outline-none">
              <h3 className="text-xl font-semibold mb-2">Can I get banned for using Aurora?</h3>
              <p className="mb-4">The usage of mods in Minecraft is subject to the game's terms of service. Use at your own risk.</p>
            </div>
            <div className="bg-primary hover:bg-accent text-white py-2 px-4 rounded-md focus:outline-none">
              <h3 className="text-xl font-semibold mb-2">Is Aurora allowed by Hypixel rules?</h3>
              <p className="mb-4">Aurora's usage on Hypixel or any other Minecraft server is against their rules. Use on your own responsibility.</p>
            </div>
            <div className="bg-primary hover:bg-accent text-white py-2 px-4 rounded-md focus:outline-none">
              <h3 className="text-xl font-semibold mb-2">Does it have Dillo spots finder?</h3>
              <p className="mb-4">Yes, Aurora includes a feature to find Dillo mining spots.</p>
            </div>
            <div className="bg-primary hover:bg-accent text-white py-2 px-4 rounded-md focus:outline-none">
              <h3 className="text-xl font-semibold mb-2">How do I install and update the mod properly?</h3>
              <p className="mb-4">Detailed installation and update instructions can be found on the official Aurora website.</p>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default Homepage;
