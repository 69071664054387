import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-background text-text py-6 px-8">
      <div className="container mx-auto flex flex-col items-center">
        <div className="text-center text-sm text-gray-400">
          <p className="">
            Made by <a href="https://discord.com/users/505301679999287299" target="_blank" rel="noopener noreferrer">@lenny.wiki</a> (contact for dev-work) &hearts;
          </p>
          &copy; {new Date().getFullYear()} Aurora Client. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
