import axios from 'axios';

const getFeatures = async () => {
  try {
    const response = await axios.get('https://api.auroraclient.lol/features');
    return response.data.features;
  } catch (error) {
    console.error('Error fetching features:', error);
    return [];
  }
};

export default getFeatures;
